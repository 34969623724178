<script>
export default {
  name: "loader",
  props: {
    color: {
      type: String,
      default: "#2e47ba"
    },
    variant: {
      type: String,
      default: 'circle-1',
      validator(value) {
        return ['circle-1', 'circle-2', 'circle-3', 'dots-1', 'dots-2', 'flat'].includes(value)
      }
    },
    animated: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    rgbColor() {
      const parsedColor = this.color.replace('#', '')
      const r = parseInt(parsedColor.substring(0, 2), 16)
      const g = parseInt(parsedColor.substring(2, 4), 16)
      const b = parseInt(parsedColor.substring(4, 6), 16)

      return `${r}, ${g}, ${b}`
    }
  }
}
</script>

<template>
  <span class="loader" :class="{ 'loader--animated': animated, [`loader--${variant}`]: true }"
    :style="{ '--color': rgbColor }">
    <template v-if="variant === 'dots-2'">
      <span></span>
      <span></span>
      <span></span>
    </template>
  </span>
</template>

<style lang="scss" scoped>
.loader {
  width: 32px;
  height: 32px;
  display: block;
  box-sizing: border-box;

  &--circle-1 {
    border: 4px solid rgba(var(--color), 1);
    border-top-color: transparent;
    border-radius: 50%;
    transform: rotate(-45deg);

    &.loader--animated {
      animation: rotation 1s linear infinite;
    }
  }

  &--circle-2 {
    position: relative;
    border: 4px solid rgba(var(--color), 0.3);
    border-top-color: rgba(var(--color), 1);
    border-right-color: rgba(var(--color), 1);
    border-radius: 50%;

    &:not(.loader--animated) {
      transform: rotate(45deg);
    }

    &.loader--animated {
      animation: rotation 1s linear infinite;
    }
  }

  &--circle-3 {
    font-size: 5px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);

    &:not(.loader--animated) {
      box-shadow: 0em -2.6em 0em 0em rgba(var(--color), 0.3), 1.8em -1.8em 0 0em rgba(var(--color), 0.5), 2.5em 0em 0 0em rgba(var(--color), 0.6), 1.75em 1.75em 0 0em rgba(var(--color), 0.7), 0em 2.5em 0 0em rgba(var(--color), 0.8), -1.8em 1.8em 0 0em rgba(var(--color), 0.9), -2.6em 0em 0 0em rgba(var(--color), 1), -1.8em -1.8em 0 0em rgba(var(--color), 0);
    }

    &.loader--animated {
      animation: mulShdSpin 1.3s infinite linear;
    }
  }

  &--dots-1 {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: block;
    position: relative;
    background: rgba(var(--color), 0.3);
    box-shadow: -12px 0 rgba(var(--color), 0.3), 12px 0 rgba(var(--color), 1);
    box-sizing: border-box;

    &:not(.loader--animated) {
      background: rgba(var(--color), 0.7);
      box-shadow: -12px 0 rgba(var(--color), 0.3), 12px 0 rgba(var(--color), 1);
    }

    &.loader--animated {
      animation: shadowPulse 2s linear infinite;
    }
  }

  &--dots-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12.5%;

    &.loader--animated span {
      animation: updown 900ms linear infinite;
    }

    &:not(.loader--animated) span {
      &:nth-child(1) {
        transform: translateY(-40%);
      }

      &:nth-child(2) {
        transform: translateY(-70%);
      }

      &:nth-child(3) {
        transform: translateY(0%);
      }
    }

    &>span {
      flex-shrink: 0;
      display: inline-block;
      background-color: rgba(var(--color), 1);
      height: 25%;
      width: 25%;
      border-radius: 50%;

      &:nth-child(2) {
        animation-delay: 300ms;
      }

      &:nth-child(3) {
        animation-delay: 600ms;
      }
    }
  }

  &--flat {
    position: relative;
    height: 6px;
    border-radius: 6px;
    background-color: rgba(var(--color), 0.3);
    overflow: hidden;

    &::after {
      content: "";
      display: block;
      height: 100%;
      width: 60%;
      border-radius: 6px;
      background-color: rgba(var(--color), 1);
    }

    &.loader--animated {
      &::after {
        animation: animloader 1.5s linear infinite;
      }
    }
  }
}

@keyframes mulShdSpin {

  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em rgba(var(--color), 1), 1.8em -1.8em 0 0em rgba(var(--color), 0), 2.5em 0em 0 0em rgba(var(--color), 0.3), 1.75em 1.75em 0 0em rgba(var(--color), 0.5), 0em 2.5em 0 0em rgba(var(--color), 0.6), -1.8em 1.8em 0 0em rgba(var(--color), 0.7), -2.6em 0em 0 0em rgba(var(--color), 0.8), -1.8em -1.8em 0 0em rgba(var(--color), 0.9);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(var(--color), 0.9), 1.8em -1.8em 0 0em rgba(var(--color), 1), 2.5em 0em 0 0em rgba(var(--color), 0), 1.75em 1.75em 0 0em rgba(var(--color), 0.3), 0em 2.5em 0 0em rgba(var(--color), 0.5), -1.8em 1.8em 0 0em rgba(var(--color), 0.6), -2.6em 0em 0 0em rgba(var(--color), 0.7), -1.8em -1.8em 0 0em rgba(var(--color), 0.8);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(var(--color), 0.6), 1.8em -1.8em 0 0em rgba(var(--color), 0.9), 2.5em 0em 0 0em rgba(var(--color), 1), 1.75em 1.75em 0 0em rgba(var(--color), 0), 0em 2.5em 0 0em rgba(var(--color), 0.3), -1.8em 1.8em 0 0em rgba(var(--color), 0.5), -2.6em 0em 0 0em rgba(var(--color), 0.6), -1.8em -1.8em 0 0em rgba(var(--color), 0.7);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(var(--color), 0.7), 1.8em -1.8em 0 0em rgba(var(--color), 0.8), 2.5em 0em 0 0em rgba(var(--color), 0.9), 1.75em 1.75em 0 0em rgba(var(--color), 1), 0em 2.5em 0 0em rgba(var(--color), 0), -1.8em 1.8em 0 0em rgba(var(--color), 0.3), -2.6em 0em 0 0em rgba(var(--color), 0.5), -1.8em -1.8em 0 0em rgba(var(--color), 0.6);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(var(--color), 0.6), 1.8em -1.8em 0 0em rgba(var(--color), 0.7), 2.5em 0em 0 0em rgba(var(--color), 0.8), 1.75em 1.75em 0 0em rgba(var(--color), 0.9), 0em 2.5em 0 0em rgba(var(--color), 1), -1.8em 1.8em 0 0em rgba(var(--color), 0), -2.6em 0em 0 0em rgba(var(--color), 0.3), -1.8em -1.8em 0 0em rgba(var(--color), 0.5);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(var(--color), 0.5), 1.8em -1.8em 0 0em rgba(var(--color), 0.6), 2.5em 0em 0 0em rgba(var(--color), 0.7), 1.75em 1.75em 0 0em rgba(var(--color), 0.8), 0em 2.5em 0 0em rgba(var(--color), 0.9), -1.8em 1.8em 0 0em rgba(var(--color), 1), -2.6em 0em 0 0em rgba(var(--color), 0), -1.8em -1.8em 0 0em rgba(var(--color), 0.3);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(var(--color), 0.3), 1.8em -1.8em 0 0em rgba(var(--color), 0.5), 2.5em 0em 0 0em rgba(var(--color), 0.6), 1.75em 1.75em 0 0em rgba(var(--color), 0.7), 0em 2.5em 0 0em rgba(var(--color), 0.8), -1.8em 1.8em 0 0em rgba(var(--color), 0.9), -2.6em 0em 0 0em rgba(var(--color), 1), -1.8em -1.8em 0 0em rgba(var(--color), 0);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(var(--color), 0), 1.8em -1.8em 0 0em rgba(var(--color), 0.3), 2.5em 0em 0 0em rgba(var(--color), 0.5), 1.75em 1.75em 0 0em rgba(var(--color), 0.6), 0em 2.5em 0 0em rgba(var(--color), 0.7), -1.8em 1.8em 0 0em rgba(var(--color), 0.8), -2.6em 0em 0 0em rgba(var(--color), 0.9), -1.8em -1.8em 0 0em rgba(var(--color), 1);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animloader {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(70%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes shadowPulse {
  33% {
    background: rgba(var(--color), 0.3);
    box-shadow: -12px 0 rgba(var(--color), 1), 12px 0 rgba(var(--color), 0.3);
  }

  66% {
    background: rgba(var(--color), 1);
    box-shadow: -12px 0 rgba(var(--color), 0.3), 12px 0 rgba(var(--color), 0.3);
  }

  100% {
    background: rgba(var(--color), 0.3);
    box-shadow: -12px 0 rgba(var(--color), 0.3), 12px 0 rgba(var(--color), 1);
  }
}

@keyframes updown {
  0% {
    transform: translateY(0%);
  }

  25% {
    transform: translateY(80%);
  }

  50% {
    transform: translateY(0%);
  }

  75% {
    transform: translateY(-80%);
  }

  100% {
    transform: translateY(0%);
  }
}
</style>