<template>
  <div
    v-if="
      data.paymentType &&
      data.paymentType.find((p) => p.active) &&
      (data.paymentType.filter((p) => p.active).length > 1 ||
        !data.showPaymentHideView)
    "
    id="theme--tuktuk__payment-method"
    class="theme--tuktuk__card"
  >
    <div class="theme--tuktuk__container">
      <h2 class="theme--tuktuk__title">{{ data.titlePayment }}</h2>
      <div class="theme--tuktuk__payment-method__list" style="gap: 0px;">
        <template
          v-for="(pType, ind) in data.paymentType.filter((p) => p.active)"
        >
          <div
            v-bind:key="ind"
            v-if="pType.type != 'E-Payment'"
            class="tuktuk__payment-method__list__option"
            @click="changeMode(pType.name)"
          >
            <vs-radio :val="pType.type" v-model="data.payload.payment" class="theme--tuktuk__radio">
              <img
                width="42px"
                height="30px"
                alt=""
                class="payment-image"
                :src="
                  pType.type == 'COD'
                    ? require('@/assets/icons/Payments/payment-cod.png')
                    : require('@/assets/icons/Payments/payment-bank_transfer.png')
                "
              />{{ pType.name }}
            </vs-radio>
          </div>

          <template v-else v-for="(elem, i) in data.epaymentSettings" >
            <div
              v-if="elem.active"
              :key="ind + i"
              @click="changeMode('E-Payment')"
              class="tuktuk__payment-method__list__option"
              :style="{ borderColor: data.payload.payment === elem.id ? 'transparent' : '#E0E9F4' }"
            >
              <vs-radio
                v-bind:key="i"
                :val="elem.id"
                v-model="data.payload.payment"
                class="theme--tuktuk__radio"
              >
                <img
                  width="42px"
                  height="30px"
                  alt=""
                  class="payment-image"
                  :src="
                    pType.id == 'COD'
                      ? require('@/assets/icons/Payments/payment-bank_transfer.png')
                      : require('@/assets/icons/Payments/' + elem.id + '.png'
                          ? '@/assets/icons/Payments/' + elem.id + '.png'
                          : '@/assets/icons/Payments/' + elem.id + '.svg')
                  "
                />
                <div>
                  {{ elem.label }}
                  <template v-if="data.ePaymentFeePaidBy === 'buyer'">
                    <p class="admin-fee m-0" v-if="elem.id === 'gopay'">{{ $t('settings.adminEPayFeeGopay') }}</p>
                    <p class="admin-fee m-0" v-else-if="elem.id === 'QRIS'">{{ $t('settings.adminEPayFeeQRIS') }}</p>
                    <p class="admin-fee m-0" v-else-if="elem.id === 'credit_card'">{{ $t('settings.adminEPayFeeCreditCard') }}</p>
                    <p class="admin-fee m-0" v-else>{{ $t('settings.adminEPayFeeOther') }}</p>
                  </template>
                </div>
              </vs-radio>
            </div>
            <div  :key="'payment-'+ind + i" v-if="data.payload.payment === elem.id" class="fs-12 fw-500 text-center" style="background-color: #D6E0F7; padding: 8px; color: #113B98; margin: 0 -15px;">
              {{ $t('settings.processByMidtrans') }}
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ["data"],
  data() {
    return {};
  },
  components: {},
  methods: {
    setStep(count) {     
      this.$emit("setStep", count);
    },
    changeMode(param) {
      this.data.payload.paymentMode = param;
    },
  },
};
</script>

<style lang="scss">
.tuktuk__payment-method__list__option {
    padding: 12px 0px;
    border-bottom: 1px solid #A1A2A433;
    &:last-of-type {
        padding-bottom: 0px;
        border-bottom: none;
    }

    .vs-radio__label {
        display: flex;
        align-items: center;

        .admin-fee {
            opacity: 0.4;
            font-size: 14px;
            font-weight: 400;
            color: #15171C;
        }
    }
}
</style>
