<template>
     <div id="theme--tuktuk__product-details" :style="{ paddingBottom: data.embed || data.themeType=='TuktukStep1' ? '0': getNavigationBottomHeight() + 'px', paddingTop: data.embed ? '0': '10px', }">
        <!-- PRODUCT IMAGE -->
        <no-ssr>
            <product-image v-if="data.showProductImages && data.productImages[0]" :productImageSetting="{enableNumber:true}" :images="data.productImages" :variantImageIndex="data.variantImageIndex" :autoSlideSettings="data.autoSlideSettings" />
        </no-ssr>

        <div class="theme--tuktuk__product-details__info theme--tuktuk__card">
            <div class="theme--tuktuk__container">
                <!-- Product Price-->
                <section v-if="data.type == 'simple'" class="theme--tuktuk__product-details__price">
                    <template v-if="parseInt(data.priceCompare) !== parseInt(data.price) && data.priceCompare > 0">
                        <h2 class="theme--tuktuk__product-details__price__sales">
                            Rp{{ data.priceCompare | g_number_format }}
                        </h2>
                        <div class="theme--tuktuk__product-details__price__container-sales">
                            <p class="theme--tuktuk__product-details__price__regular">
                                <template v-if="data.price">
                                    Rp{{ (data.price) | g_number_format }}
                                </template>
                            </p>
                            <div v-if="percentageCal > 0" class="theme--tuktuk__label--pink">
                                {{ $t("tuktukTheme.saveUpTo") }} {{ percentageCal }}%
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <h2 class="theme--tuktuk__product-details__price__regular">
                            Rp{{ data.price | g_number_format }}
                        </h2>

                    </template>

                </section>
                <section v-else class="theme--tuktuk__product-details__price">
                    <template v-if="variant.lowestSalePrice &&
                        variant.lowestSalePrice > 0 &&
                        variant.highestSalePrice != -Infinity">
                        <template v-if="variant.highestSalePrice > 0 &&
                            parseInt(variant.lowestSalePrice) !==
                            parseInt(variant.highestSalePrice)">
                            <h2 class="theme--tuktuk__product-details__price__sales">
                                Rp{{ variant.lowestSalePrice | g_number_format }} - Rp{{ variant.highestSalePrice |
                                    g_number_format }}
                            </h2>
                        </template>
                        <template v-else>
                            <h2 class="theme--tuktuk__product-details__price__sales">
                                Rp{{ variant.highestSalePrice | g_number_format }}
                            </h2>
                        </template>
                        <div class="theme--tuktuk__product-details__price__container-sales">
                            <p class="theme--tuktuk__product-details__price__regular">
                                Rp{{ variant.highestRegularPrice | g_number_format }}
                            </p>
                            <div v-if="percentageCal > 0" class="theme--tuktuk__label--pink">
                                {{ $t("tuktukTheme.saveUpTo") }} {{ percentageCal }}%
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <h2 class="theme--tuktuk__product-details__price__regular">
                            <template v-if="variant.highestRegularPrice > 0 &&
                                parseInt(variant.lowestRegularPrice) !==
                                parseInt(variant.highestRegularPrice)">
                                Rp{{ variant.lowestRegularPrice | g_number_format }} - Rp{{ variant.highestRegularPrice |
                                    g_number_format }}

                            </template>
                            <template v-else>
                                Rp{{ variant.highestRegularPrice | g_number_format }}
                            </template>
                        </h2>
                    </template>                  

                </section>

                <!-- Product Name-->
                <h1 class="theme--tuktuk__title"
                    v-if="data.tuktukThemeSetting && data.tuktukThemeSetting.isCheckoutNameAsProduct">
                    {{ data.pageName }}
                </h1>
                <h1 class="theme--tuktuk__title" v-else>
                    {{ data.name }}
                </h1>

                <!-- Rating Section-->
                <section v-if="data.tuktukThemeSetting && data.showRatingItemSold"
                    class="theme--tuktuk__product-details__statistic">
                    <div v-if="data.tuktukThemeSetting.currentRatingValue"
                        class="theme--tuktuk__product-details__statistic__rating">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
                            <path
                                d="M5.54931 0.43813C5.7309 0.0601522 6.2691 0.0601528 6.45069 0.438131L7.61837 2.86871C7.69125 3.02043 7.83559 3.12529 8.00241 3.14773L10.6749 3.50717C11.0904 3.56306 11.2568 4.07493 10.9534 4.36443L9.00261 6.22604C8.88084 6.34225 8.82571 6.51192 8.85592 6.67751L9.33991 9.33023C9.41517 9.74275 8.97975 10.0591 8.61068 9.86005L6.23735 8.58001C6.0892 8.50011 5.9108 8.50011 5.76265 8.58001L3.38932 9.86005C3.02025 10.0591 2.58483 9.74275 2.66009 9.33023L3.14408 6.67751C3.17429 6.51192 3.11916 6.34225 2.99739 6.22604L1.04661 4.36443C0.74324 4.07493 0.909556 3.56306 1.32515 3.50717L3.99759 3.14773C4.16441 3.12529 4.30875 3.02043 4.38163 2.86871L5.54931 0.43813Z"
                                :fill="data.tuktukThemeSetting.ratingColor ? data.tuktukThemeSetting.ratingColor : '#F6D14B'" />
                        </svg>
                        <p v-if="data.tuktukThemeSetting.currentRatingValue || data.tuktukThemeSetting.numberOfUsers">
                            <template v-if="data.tuktukThemeSetting.currentRatingValue">{{
                                data.tuktukThemeSetting.currentRatingValue }}/{{ data.tuktukThemeSetting.maxRatingValue
    }}</template>
                            <span v-if="data.tuktukThemeSetting.numberOfUsers"> ({{ data.tuktukThemeSetting.numberOfUsers
                            }})</span>
                        </p>
                    </div>
                    <svg v-if="data.tuktukThemeSetting.itemSoldAmount" xmlns="http://www.w3.org/2000/svg" width="2"
                        height="13" viewBox="0 0 2 13" fill="none">
                        <path d="M1 0.5V12.5" stroke="#A1A2A4" />
                    </svg>
                    <p v-if="data.tuktukThemeSetting.itemSoldAmount"
                        class="theme--tuktuk__product-details__statistic__sold">
                        {{ data.tuktukThemeSetting.itemSoldAmount | g_number_format }} <span> {{ $t("tuktukTheme.sold") }}</span>
                    </p>
                </section>
                <Seal :data="{ ...data }" v-if="data.showSeals && data.sealText && data.sealText.length > 0" />
            </div>
        </div>

        <!--SelectOptions -->
        <no-ssr>
            <SelectOptions @updateCartQuantity="updateCartQuantity" :data="{ ...data }" :payload="data.payload" @addCartItem="addCartItem"
            @addToCart="addToCart" @showMsg="showMsg" :isVariantModalOpen="isVariantModalOpen" @setStep="setStep" @changeVariant="changeVariant" @updateCartSummary="updateCartSummary" />
        </no-ssr>
        <!-- Coupon -->
        <Coupon v-if="data.themeType=='TuktukStep1' && data.showCoupon" :data="{ ...data }" @updateDiscountLists="updateDiscountLists"  @applyDiscount="applyDiscount" @clearDiscount="clearDiscount"  />
        

        <!-- REVIEWS-->
        <div id="Reviews" ref="Reviews"></div>
        <div class="theme--tuktuk__product-details__testimoni theme--tuktuk__card"
            v-if="data.testimonials.length && data.showTestimonials">
            <Bottom :data="data" />
        </div>


        <div v-if="data.showVideo && videoUrl != '' || data.showContentBullet || data.showBumpProductStep1"
            class="theme--tuktuk__product-details__others theme--tuktuk__card">
            <div id="Description" ref="Description"></div>
            <div class="theme--tuktuk__product-details__others__title">
                <p>{{ $t("tuktukTheme.aboutThisProduct") }}</p>
            </div>

            <!-- YOUTUBE -->
            <div v-if="data.showVideo && videoUrl != ''" class="theme--tuktuk__product-details__others__video">
                <!-- <iframe :src="'https://www.youtube.com/embed/' + videoUrl" allowfullscreen></iframe> -->               
                <YouTubeVideo :videoId="videoUrl.trim()"/>
            </div>

            <!-- DESCRIPTION and BULLETS -->
            <div v-if="data.showContentBullet" class="theme--tuktuk__container flex flex-column">
                <!-- DESCRIPTION -->
                <div v-if="data.content" class="theme--tuktuk__product-details__others__description" v-html="data.content">
                </div>
                <!-- BULLETS -->
                <div v-if="data.bullets.length" class="theme--tuktuk__product-details__others__bullet">
                    <div v-html="data.titleBullet" class="theme--tuktuk__sub-title"></div>
                    <div class="theme--tuktuk__product-details__others__bullet__list">
                        <div :key="bullet.name" v-for="(bullet, index) in data.bullets"
                            class="theme--tuktuk__product-details__others__bullet__list__item">
                            <img :alt="'bullet-' + index" src="@/assets/icons/TuktukTheme/checklist--pink.svg" />
                            <p>{{ bullet.name }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Bump order step 1-->
            <div v-if="data.showBumpProductStep1" class="theme--tuktuk__container">
                <BumpOrder :data="{ ...data }" />
            </div>
        </div>



        <!--Buy Now -->
        <BuyNow @setStep="setStep" :data="{ ...data,isVariantModalOpen }" @openVariantModal="openVariantModal" @updateCartQuantity="updateCartQuantity" @updateCartSummary="updateCartSummary" @changeVariant="changeVariant"  />
    </div>
</template>
<script>
import Bottom from "./../../bottom.vue";
import config from "./../../../../config.js";
import BuyNow from "./BuyNow.vue";
import SelectOptions from "./SelectOptions.vue";
import Seal from "./Seal.vue";
import BumpOrder from "./../Checkout/BumpOrder.vue";
import ProductImage from "../../../ProductImage.vue";
import { getSizedImageUrl } from "@/helpers/imagekitHelper";
import Coupon from "./Coupon.vue";
import YouTubeVideo from "../../../YouTubeVideo.vue";

export default {
    props: ["data", "cartQuantity"],
    data() {
        return {
            baseUrl: config.api.replace("/api", ""),
            videoUrl: "",
            percentageCal: 0,
            isVariantModalOpen: false,
            variant: {
                lowestSalePrice: 0,
                highestSalePrice: 0,
                highestRegularPrice: 0,
                lowestRegularPrice: 0
            },
            getSizedImageUrl,
            isMobile:(this.data.nuxt2 && process.client) || !this.data.nuxt2 ? window.innerWidth < 575 : false,
        };
    },
    components: {
        Bottom,
        BuyNow,
        SelectOptions,
        Seal,
        BumpOrder,
        ProductImage,
        Coupon,
        YouTubeVideo
    },
    watch:{
        "data.changeVariant": {
      handler(val) {        
        this.isVariantModalOpen=val;      
       // this.$emit("changeVariant", val);
      }
    }, 
    },
    filters: {
        g_number_format(val) {
            return new Intl.NumberFormat().format(val);
        },
    },
    mounted() {
       
        this.$nextTick(() => {
            if((this.data.nuxt2 && process.client) || !this.data.nuxt2){
                window.addEventListener('resize', this.onResize);
            }
            
        })
        this.getVideoId();
        if (this.data.type == "simple") {
            if (this.data.priceCompare > 0) {
                this.percentageCal = parseInt(
                    ((this.data.price - this.data.priceCompare) * 100) / this.data.price
                );
            }
        } else {

            let lowestSalePrice = Infinity;
            let highestSalePrice = -Infinity;
            let lowestRegularPrice = Infinity;
            let highestRegularPrice = -Infinity;

            this.data.variablePrices = this.data.variablePrices.map(item => {

                if (!item.delete) {
                    if (item.priceCompare && item.priceCompare > highestSalePrice) {
                        highestSalePrice = item.priceCompare
                    }

                    if (item.priceCompare && item.priceCompare < lowestSalePrice) {
                        lowestSalePrice = item.priceCompare
                    }

                    if (item.price > highestRegularPrice) {
                        highestRegularPrice = item.price
                    }


                    if (item.price < lowestRegularPrice) {
                        lowestRegularPrice = item.price
                    }
                    if (
                        item.priceCompare &&
                        item.priceCompare > 0
                    ) {
                        item['percentageCal'] = parseInt(
                            ((item.price -
                                item.priceCompare) *
                                100) /
                            item.price
                        );
                    } else {
                        item['percentageCal'] = 0;
                    }
                } else {
                    item['percentageCal'] = 0;
                }

                return item;

            })

            this.variant = {
                lowestSalePrice,
                highestSalePrice,
                highestRegularPrice,
                lowestRegularPrice
            };

            const maxSalePrice = this.data.variablePrices.reduce((max, item) => Math.max(max, item.percentageCal), -Infinity);

            if (
                maxSalePrice > 0
            ) {
                this.percentageCal = parseInt(maxSalePrice);
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        updateDiscountLists(discounts,replace){           
            this.$emit('updateDiscountLists',discounts,replace)
        },
        applyDiscount(val) {
            this.$emit("applyDiscount", val);
        },
        clearDiscount() {
            this.$emit("clearDiscount");
        },
        onResize() {
            this.isMobile = window.innerWidth < 575;
        },
        setStep(count) {
            this.$emit("setStep", count);
        },
        changeVariant(val) {
            this.$emit("changeVariant", val);
        },
        addToCart() {
            this.$emit("addToCart");
        },
        showMsg() {
            this.$emit("showMsg");
        },
       
        updateCartQuantity(qty) {
            this.$emit("updateCartQuantity", qty);
        },
        addCartItem() {
            this.$emit("addCartItem");
        },
        updateCartSummary(index) {
            this.$emit("updateCartSummary", index);
        },
        getVideoId() {
            let val = this.data.componentVideo;
            // var regExp =
            //   /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            // if (val) {
            //   var match = val.match(regExp);
            //   this.videoId = match && match[7].length == 11 ? match[7] : false;
            // }    
            if (val !== "" && val) {
                if (val.includes("https://youtu.be/")) {
                    this.videoUrl =
                        val.split("/youtu.be/").length > 1
                            ? val.split("/youtu.be/")[1]
                            : val;
                } else if (val.includes("?")) {
                    this.videoUrl =
                        val.split("v=").length > 1 ? val.split("v=")[1] : val;
                } else {
                    this.videoUrl = val;
                }
            } else {
                this.videoUrl = val;
            }
        },
        getNavigationBottomHeight(initHeight = 120) {
            if((this.data.nuxt2 && process.client) || !this.data.nuxt2){            
                const elementNavigationBottom = document.querySelector(".theme--tuktuk__navigation-bottom")
                if(elementNavigationBottom){
                    return elementNavigationBottom.clientHeight;
                }
            }
            return initHeight;
        },
        openVariantModal(val) {
            this.isVariantModalOpen = val;
        },
    }
};
</script>
