<script>
import moment from "moment";

export default {
    props: [
        "discount",
        'theme',
        'payload',
        'IsDisabled'

    ],
    components: {
        
    },
    data() {
        return {
          moment,
          iconAlertRequirement: {
            classic: require('@/assets/icons/alert-circle-filled.svg'),
            tuktuk: require('@/assets/icons/TuktukTheme/alert-circle-pink-filled.svg'),
          },
        }
    },
    mounted() {

    },
    watch: {

    },
    methods: {
        clearDiscountCode(){
            this.$emit('clearDiscountCode')
        },        
        applyDiscount(discount){
            this.$emit('applyDiscount',discount)
        }

    },
    computed: {
    assets() {
      return {
        icon: {
          tuktuk: require('@/assets/icons/discount-circle-red.svg'),
          classic: require('@/assets/icons/discount-circle.svg'),
        },
        moneyOffProduct: {
          tuktuk: require('@/assets/images/illustrations/icon-illustration-coupon-item.svg'),
          classic: require('@/assets/images/illustrations/icon-illustration-coupon-item.svg'),
        },
        moneyOffOrder: {
          tuktuk: require('@/assets/images/illustrations/icon-illustration-coupon-order.svg'),
          classic: require('@/assets/images/illustrations/icon-illustration-coupon-order.svg'),
        },
        moneyOffShipping: {
          tuktuk: require('@/assets/images/illustrations/icon-illustration-coupon-shipping.svg'),
          classic: require('@/assets/images/illustrations/icon-illustration-coupon-shipping.svg'),
        },
        empty: {
          tuktuk: require('@/assets/images/empty-state/coupons-empty-red.svg'),
          classic: require('@/assets/images/empty-state/coupons-empty-green.svg'),
        }
      }
    },
    width() {
      return this.theme === 'classic' ? '578px' : '530px'
    }
  },
}
</script>

<template>
    <div class="coupon__item coupon__item__shipping">
        <div class="flex items-center">
            <img :src="assets[discount.discount_type][theme || 'classic']" alt="O">
            <div class="coupon__item__divider"></div>
        </div>
        <div class="flex flex-column">
          <div class="flex">
        <div class="flex-grow-1">
            <p class="coupon__item__name">{{ discount.title }}</p>
            <template v-if="discount.discount_value.type === 'fixed'">
                <p class="coupon__item__code">{{ $t('coupon.Discount') }} {{ 'RP'
                    + Number(discount.discount_value.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</p>
            </template>
            <template v-else>
                <p class="coupon__item__code">{{ $t('coupon.Discount') }} {{ discount.discount_value.value ?
                    discount.discount_value.value + "% " : "" }}</p>
            </template>
            <p class="coupon__item__description" v-if="discount.end_date">        
                    Valid {{ moment(discount.full_start_date).format('DD/MM/YYYY') }} - {{ moment(discount.full_end_date).format('DD/MM/YYYY') }}
                </p>           
        </div>        
        <button :class="{'disabled':!IsDisabled}" :disabled="!IsDisabled" @click="applyDiscount(discount)"  v-if="payload.discount.code!=discount.title" class="pp-button--fill">{{$t('coupon.ClaimCoupon')}}</button>
        <button @click="clearDiscountCode" v-else class="pp-button--border">{{$t('coupon.Wipe')}}</button>
        <!-- <div class="coupon__item__shipping__products">
            <hr style="border-style: dashed; border-color: #BFCEE0; margin: 0 0 8px; width: 100%;">
            <div class="flex align-items-start" style="gap: 4px;">
                <svg width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM9 10C9 10.5523 9.44772 11 10 11C10.5523 11 11 10.5523 11 10L11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6V10ZM9 13.5C9 14.0523 9.44772 14.5 10 14.5C10.5523 14.5 11 14.0523 11 13.5C11 12.9477 10.5523 12.5 10 12.5C9.44772 12.5 9 12.9477 9 13.5Z" fill="#EC980C"/>
                </svg>
                <span class="fs-14 fw-400">Untuk produk Nike Hypervenom, Adidas 1897, Proyek III, Specter Shoes, Nike Hypervenom, Adidas 1897, Proyek III, Specter Shoes</span>   
            </div>               
        </div>  -->
        </div>
        <template v-if="discount.minimum_requirements.type == 'minimum_purchase' || discount.minimum_requirements.type == 'minimum_quantity'">
          <hr class="coupon-separator" :class="[theme]" />
          <div class="additional-requirement">
            <template v-if="discount.minimum_requirements.type == 'minimum_purchase'">
              <div class="item-requirement">
                <img class="info-requirement" :src="iconAlertRequirement[theme]" alt="O">
                <p class="description-requirement coupon__item__description" :class="[theme]">
                    {{ discount.minimum_requirements.value
                        ? `Minimum purchase of RP
                    ${Number(discount.minimum_requirements.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        : $t('coupon.NoMinPurchaseRequirement')
                    }}
                </p>
              </div>
            </template>

            <template v-else-if="discount.minimum_requirements.type == 'minimum_quantity'">
              <div class="item-requirement">
                <img class="info-requirement" :src="iconAlertRequirement[theme]" alt="O">
                <p class="description-requirement coupon__item__description" :class="[theme]">
                  {{ discount.minimum_requirements.value
                      ? `Minimum purchase of ${discount.minimum_requirements.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                          ",")} items`
                      : $t('coupon.NoMinPurchaseRequirement')
                  }}
                </p>
              </div>
            </template>
          </div>
        </template>
        </div>
    </div>
</template>


<style lang="scss" scoped>
[data-theme="tuktuk"] {
  .coupon-show-more {
    display: flex;
    justify-content: center;
    align-items: center;

    &[data-expanded=true] {
      button svg {
        transform: rotate(180deg);
      }
    }

    button {
      height: unset !important;
      padding: 0 !important;
      margin-top: 8px;
      border-radius: 0 !important;
      background: none !important;
      display: flex;
      align-items: center;
      gap: 4px;
      color: #F75656;
      font-size: 14px;
      font-weight: 500;
    }
  }

  h3 {
    font-family: TikTokDisplayMedium !important;
    font-weight: 600;
  }

  .coupon__item {
    background: #FEEBEB;
    border-radius: 16px;
    row-gap: 0 !important;

    @media screen and (max-width: 500px) {
      grid-template-columns: auto 1fr !important;
      grid-template-rows: auto auto;
      row-gap: 16px !important;
      padding: 16px !important;
    }

    &::before {
      content: '';
      position: absolute;
      top: -12px;
      left: 56px;
      display: block;
      height: 24px;
      width: 24px;
      border-radius: 48px;
      background: #FFF;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -12px;
      left: 56px;
      display: block;
      height: 24px;
      width: 24px;
      border-radius: 48px;
      background: #FFF;
    }

    &>div:first-child {
      grid-row: 1 / 3;
    }

    img {
      margin-right: 24px;
      height: 40px;
      width: 40px;
    }
    
    &__shipping__products {
      grid-column: 2 / 4;

      hr {
        border-color: #F75656;
      }
      span {
        color: #F53535 !important;
      }
      path {
        fill: #F53535 !important;
      }
    }
    
    &__divider {
      position: absolute;
      top: 50%;
      left: 56px;
      transform: translateY(-50%);
      height: 140px;
      margin: 0 10px;
      border-left: 4px dashed #FFF;
    }

    &__name {
      font-family: TikTokDisplayBold;
      margin-bottom: 4px;
      color: #203551;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;

      @media screen and (max-width: 500px) {
        margin-bottom: 0px;
      }
    }
    &__code {
      margin-bottom: 4px;
      color: #F53535;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;

      @media screen and (max-width: 500px) {
        font-size: 12px;
        margin-bottom: 0px;
        line-height: 18px;
      }
    }
    &__description {
      margin-bottom: 0px;
      color: #15171C;
      opacity: 0.4;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      @media screen and (max-width: 500px) {
        font-size: 12px;
        margin-bottom: 0px;
        line-height: 18px;
      }
    }
  }
  .pp-button {
    &--fill {
      background: #F75656;

      @media screen and (max-width: 500px) {
        width: 100% !important;
        grid-column: 2 / 3;
      }
    }
    &--border {
      color: #F75656;
      background: #fff;
      border: 1px solid #F75656 !important;

      @media screen and (max-width: 500px) {
        width: 100% !important;
        grid-column: 2 / 3;
      }
    }
  }
  &::v-deep {
    .vs-pagination {
      gap: 8px !important;

      &-content {
        gap: 8px !important;
      }

      &__button {
        color: #EB435B;
        background-color: #FFF;
        border-radius: 40px;
        border: 1px solid #E0E9F4;
        border-radius: 40px !important;
      }

      &__active {
        color: #FFF;
        background-color: #EB435B !important;
        box-shadow: 0px 10px 20px 0px #00136A40;
        border-radius: 40px !important;
      }

      &__arrow {
        background-color: #FFF;
        border: 1px solid #EB435B !important;
        border-radius: 40px !important;
        
        .vs-icon-arrow::before,
        .vs-icon-arrow::after {
          background-color: #EB435B !important;
        }

        &:disabled {
          opacity: 1 !important;
          border: 1px solid #E0E9F4 !important;
          
          .vs-icon-arrow::before,
          .vs-icon-arrow::after {
            background-color: #203551 !important;
          }
        }
      }
    }
  }
}
[data-theme="classic"] {
  .coupon-show-more {
    display: flex;
    justify-content: center;
    align-items: center;

    &[data-expanded=true] {
      button svg {
        transform: rotate(180deg);
      }
    }

    button {
      height: unset !important;
      padding: 0 !important;
      margin-top: 8px;
      border-radius: 0 !important;
      background: none !important;
      display: flex;
      align-items: center;
      gap: 4px;
      color: #14AD4D;
      font-size: 14px;
      font-weight: 700;
    };
  }

  .coupon__item {
    background: #FFF;
    border-radius: 8px;
    border: 1px solid #E0E9F4;

    @media screen and (max-width: 500px) {
      gap: 8px !important;
      grid-template-columns: auto 1fr !important;
      grid-template-rows: auto auto;
      padding: 12px !important;
    }

    img {
      height: 64px;
      width: 64px;
    }
    
    &__divider {
      display: none;
    }
    
    &__shipping__products {
      grid-column: 1 / 4;

      hr {
        border-color: #BFCEE0;
      }
      span {
        color: #9D6508 !important;
      }
      fill {
        fill: #9D6508 !important;
      }
    }

    &__name {
      margin-bottom: 4px;
      color: #203551;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;

      @media screen and (max-width: 500px) {
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
    }
    &__code {
      margin-bottom: 4px;
      color: #14AD4D;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;

      @media screen and (max-width: 500px) {
        margin-bottom: 0px;
        font-size: 12px;
        line-height: 18px;
      }
    }
    &__description {
      margin-bottom: 0px;
      color: #15171C;
      opacity: 0.4;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      @media screen and (max-width: 500px) {
        margin-bottom: 0px;
        font-size: 12px;
        line-height: 18px;
      }
    }

  }
  .pp-button {
    &--fill {
      background: #3BBA6A;
      box-shadow: 0px 1px 2px 0px #0000001F;

      @media screen and (max-width: 500px) {
        width: 100% !important;
        grid-column: 1 / 3;
      }
    }
    &--border {
      color: #14AD4D;
      background: #fff;
      border: 1px solid #14AD4D !important;

      @media screen and (max-width: 500px) {
        width: 100% !important;
        grid-column: 1 / 3;
      }
    }
  }
  &::v-deep {
    .vs-pagination {
      gap: 8px !important;

      &-content {
        gap: 8px !important;
      }

      &__button {
        color: #14AD4D;
        background-color: #FFF;
        border-radius: 40px;
        border: 1px solid #E0E9F4;
        border-radius: 40px !important;
      }

      &__active {
        color: #FFF;
        background-color: #14AD4D !important;
        box-shadow: 0px 10px 20px 0px #00136A40;
        border-radius: 40px !important;
      }

      &__arrow {
        background-color: #FFF;
        border: 1px solid #14AD4D !important;
        border-radius: 40px !important;
        
        .vs-icon-arrow::before,
        .vs-icon-arrow::after {
          background-color: #14AD4D !important;
        }

        &:disabled {
          opacity: 1 !important;
          border: 1px solid #E0E9F4 !important;
          
          .vs-icon-arrow::before,
          .vs-icon-arrow::after {
            background-color: #203551 !important;
          }
        }
      }
    }
  }
}

.pp-modal-vuesax {
  &__header {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 24px;

    h3 {
      font-size: 24px;
    }

    @media screen and (max-width: 500px) {
      padding: 16px 20px;

      h3 {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  &__main {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    min-height: 570px;
    max-height: 570px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 12px;
      cursor: pointer;
    }
    &::-webkit-scrollbar-track {
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background: #BFCEE0;
      border: 3px solid transparent;
      border-radius: 8px;
      background-clip: padding-box;  
    }

    @media screen and (max-width: 500px) {
      padding: 16px;
      gap: 16px;
    }

    .coupon__item {
      position: relative;
      flex-shrink: 0;
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: 16px;
      padding: 9px 16px;
      letter-spacing: -0.2px;
      overflow: hidden;

      &:not(:last-of-type) {
        margin-bottom: 8px;
      }

      &__shipping {
        grid-template-rows: auto auto;

        svg {
          flex-shrink: 0;
        }
      }

      &__valid {
        margin-bottom: 0px;
        opacity: 0.4;
        color: #15171C;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .pp-button {
      &--fill {
        align-self: center;
        width: 114px;
        height: 32px !important;
        flex-shrink: 0;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        letter-spacing: -0.2px;
      }
      &--border {
        align-self: center;
        width: 114px;
        height: 32px !important;
        flex-shrink: 0;
        background: #FFF;
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        letter-spacing: -0.2px;
        box-shadow: 0px 1px 2px 0px #0000001F;
      }
    }
  }

  .search-white {
    font-size: 14px;
    background: #ffffff;
    border-radius: 10px;
    padding: 10px 15px 10px 35px;
    background-image: url(/img/search.svg);
    background-position: 12px 12px;
    background-repeat: no-repeat;
    border: 1px solid #e7edf4;
    border-color: #E0E9F4 !important;
    box-shadow: 0px 1px 2px 0px #1018280D;
  }

  &::v-deep {
    .vs-dialog {
      min-width: 0 !important;
      height: 654px;
      border-radius: 16px !important;
      overflow: hidden;

      &__content {
        margin-bottom: 0 !important;
      }

      &__close {
        top: 24px !important;
        right: 24px !important;
        background-color: #F6F8FC !important;
        padding: 2px !important;

        @media screen and (max-width: 500px) {
          top: 16px !important;
          right: 20px !important;
        }

        i {
          width: 24px;
          height: 24px;

          &::before,
          &::after {
            top: 13px;
            right: 7px;
            background: #BFCEE0 !important;
            height: 1.5px !important;
          }
        }
      }
    }
  }
}

button {
  height: 40px;
  color: #FFF;
  border: none;
  border-radius: 8px;
  border-color: #14AD4D !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

.fw-700 {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.gap-16 {
  gap: 16px;
}

.color-mute {
  color: #6C7E95
}

.flex-grow-1 {
  flex-grow: 1;
}

.coupon-separator {
  margin: 8px 0px;
  width: 100%;

  &.tuktuk {
    border: 1px dashed #F75656;
  }

  &.classic {
    border: 1px dashed #BFCEE0;
  }
}

.additional-requirement {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .item-requirement {
    display: flex;
    gap: 4px;
    align-items: center;

    .info-requirement {
      height: 16px !important;
      width: 16px !important;
      margin: 0px !important;
    }

    .description-requirement {
      font-size: 14px;
      font-weight: 400;
      opacity: 1;

      &.tuktuk {
        font-family: TikTokDisplayRegular;
        color: #F53535
      }

      &.classic {
        color: #9D6508;
      }
    }
  }
}
</style>